//Helpers
import {
  to,
  deleteAllCookies,
  deleteLocalStorageTokens,
} from '../../lib/helpers';
import { publicRoutes } from '../../routes/RoutesPublic';
import { gaActions } from '../gaActionTypes';
import { selectSignInParamValue } from './authSelectors';
import {
  retryOnException,
  cancelCallAfterTimeout,
} from '../../lib/helpers/util';

// Requests
import {
  logoutRequest,
  checkForSignInAccess,
  getTokenRequest,
  getSDKKeyRequest,
} from '../../backend/requests';

// Actions
import { getUser } from '../user/getUser/getUserActions';
import { clearUser } from '../user/clearUser/clearUserActions';
import { replace } from 'connected-react-router';
import { closeCurrentModal } from '../modal/modalActions';
import { resetOnboardingState } from '../onboarding/onboardingActions';
import { SESSION_ENDED } from '../session/sessionActions';
import { SERVER_ERROR } from '../error/errorActions';

// Consts
export const AUTH_STORE_REDIRECT_LOCATION =
  '[AUTH] Store Login Redirect Location';
export const AUTH_GETTING_SESSION = '[AUTH] Getting Session';
export const AUTH_STORE_SESSION = '[AUTH] Store Session';
export const AUTH_CLEAR_SESSION = `[AUTH] ${gaActions.session} Clear Session`;
export const AUTH_STORE_SIGNIN_PARAMVALUE = '[AUTH] Store Sign-In Param Value';
export const AUTH_CLEAR_SIGNIN_PARAMVALUE = '[AUTH] Clear Sign-In Param Value';
export const AUTH_STORE_IS_PERMITTED_TO_AUTHENTICATE_VIA_IDOLOGY =
  '[AUTH] Store Is Permitted to Authenticate Via Idology';
export const AUTH_STORING_DEVICE_ID = '[AUTH] Storing Device Id';
export const AUTH_STORE_SOCURE_SDK_KEYS = '[AUTH] Store Socure SDK Keys';

//DEPRECATED
// export const storeRedirectLocation = redirectLocation => dispatch =>
//   dispatch({ type: AUTH_STORE_REDIRECT_LOCATION, payload: redirectLocation });

export const getSession = () => async dispatch => {
  dispatch({ type: AUTH_GETTING_SESSION });
  // eslint-disable-next-line
  const [_, err] = await to(dispatch(getUser()));

  if (err) return dispatch(clearSession());

  //dispatch(storeSession());
  //console.warn('Trying to call storeSession');
};

export const getSDKKeys = () => async dispatch => {
  const [tokenResponse, tokenError] = await to(dispatch(getTokenRequest()));

  if (tokenError) {
    console.error('Error fetching token for SDK keys', tokenError);
    return Promise.reject(tokenError);
  }
  const { token, uniqueIdentifier } = tokenResponse.body;

  const [keysResponse, keysError] = await to(
    dispatch(getSDKKeyRequest(token, uniqueIdentifier))
  );

  if (keysError) {
    console.error('Error fetching SDK keys', keysError);
    return Promise.reject(keysError);
  }

  const { socureKeys } = keysResponse.body;
  return dispatch({
    type: AUTH_STORE_SOCURE_SDK_KEYS,
    payload: {
      socureKeys: JSON.parse(socureKeys),
    },
  });
};

export const getDeviceId = () => async dispatch => {
  try {
    if (!window.SigmaDeviceManager) {
      throw new Error('SigmaDeviceManager is not defined');
    }

    /*
     * Socure hangs if the SDK key is incorrect,
     * so we need to cancel the request after a timeout.
     */
    const fetchSessionId = cancelCallAfterTimeout(
      window.SigmaDeviceManager.getDeviceSessionId,
      2000,
      'Socure sessionId request timed out'
    );

    const sessionId = await retryOnException(fetchSessionId);
    return dispatch({
      type: AUTH_STORING_DEVICE_ID,
      payload: sessionId,
    });
  } catch (error) {
    dispatch({ type: SERVER_ERROR, payload: {} });
    console.error(error);
    return Promise.reject(error);
  }
};

export const storeSession = action => dispatch => {
  localStorage.setItem('access_token', action.access_token);
  localStorage.setItem('refresh_token', action.refresh_token);
  localStorage.setItem('session_token', action.session_token);
  dispatch({ type: AUTH_STORE_SESSION });
};

export const clearSession = () => (dispatch, getState) =>
  getState().auth.isAuthenticated
    ? dispatch({ type: AUTH_CLEAR_SESSION })
    : null;

export const redirectToSignIn = () => (dispatch, getState) => {
  deleteLocalStorageTokens();
  deleteAllCookies();
  dispatch(clearSession());
  dispatch(clearUser());

  let { pathname } = getState().router.location;

  if (!publicRoutes.includes(pathname)) dispatch(replace('/'));
};

export const verifyAllowSignIn = paramValue => async dispatch => {
  const [{ body }, err] = await to(dispatch(checkForSignInAccess(paramValue)));
  if (err || body.status === 'ERROR') {
    return Promise.reject();
  } else {
    let validSignInParam = body.data.key;
    let isPermittedToAuthenticateViaIdology = body.data.kbVerificationStatus;
    dispatch({ type: AUTH_STORE_SIGNIN_PARAMVALUE, payload: validSignInParam });
    dispatch({
      type: AUTH_STORE_IS_PERMITTED_TO_AUTHENTICATE_VIA_IDOLOGY,
      payload: isPermittedToAuthenticateViaIdology,
    });
    return Promise.resolve(validSignInParam);
  }
};

export const storeWelcomeParam = paramValue => dispatch => {
  return dispatch({ type: AUTH_STORE_SIGNIN_PARAMVALUE, payload: paramValue });
};

export const clearWelcomeParam = () => dispatch => {
  return dispatch({ type: AUTH_CLEAR_SIGNIN_PARAMVALUE });
};

export const signOut = () => async (dispatch, getState) => {
  const signInParam = selectSignInParamValue(getState());
  await dispatch(logoutRequest());
  deleteLocalStorageTokens();
  deleteAllCookies();
  dispatch(clearSession());
  dispatch(closeCurrentModal());
  dispatch(resetOnboardingState());
  dispatch(clearUser());
  dispatch(replace(`/sign-in${signInParam ? `?welcome=${signInParam}` : ''}`));
  dispatch({ type: SESSION_ENDED });
};

// These sign-out functions, allow for a multi-step signout (such as when a user changes their email)

export const signOutWithoutRedirect = () => async dispatch => {
  await dispatch(logoutRequest());
  deleteLocalStorageTokens();
  deleteAllCookies();
  dispatch(resetOnboardingState());
};

// Note: this does not sign the user out from the Forgerock session, so only use it with signOutWithoutRedirect

export const signOutWithoutAPICall = () => (dispatch, getState) => {
  const signInParam = selectSignInParamValue(getState());
  deleteLocalStorageTokens();
  deleteAllCookies();
  dispatch(clearSession());
  dispatch(closeCurrentModal());
  dispatch(resetOnboardingState());
  dispatch(clearUser());
  dispatch(replace(`/sign-in${signInParam ? `?welcome=${signInParam}` : ''}`));
};
